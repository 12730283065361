import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { i18n } from '@/utils/i18n';
import '@/utils/icons';
import { getPrimeVueStrings } from '@/utils/primevue-strings';
import { VueFire, VueFireAuth } from 'vuefire';
import { createPinia } from 'pinia';

import { firebaseApp } from './firebase';

import { router } from './router/router';

import App from './App.vue';

import 'virtual:uno.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

const pinia = createPinia();

/*
  name the app instance "Vue" instead of "app" for better JetBrains IDE support
  @see "workaround" mentioned here https://youtrack.jetbrains.com/issue/WEB-48239/Vue-3-global-components-not-recognised
 */
const Vue = createApp(App);

Vue.use(pinia);
Vue.use(VueFire, { firebaseApp, modules: [VueFireAuth()] });
Vue.use(ToastService);
Vue.use(ConfirmationService);
Vue.directive('tooltip', Tooltip);
Vue.use(i18n);
Vue.use(router);

getPrimeVueStrings().then((locale) => {
  Vue.use(PrimeVue, {
    ripple: true,
    locale,
  });
});

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

if (import.meta.env.VITE_SENTRY_DSN_BACKEND) {
  Sentry.init({
    app: Vue,
    dsn: import.meta.env.VITE_SENTRY_DSN_BACKEND,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', /^\//],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: import.meta.env.PROD ? 0.75 : 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    logErrors: import.meta.env.DEV,
    enabled: import.meta.env.PROD,
    environment: import.meta.env.APP_ENV,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
}

Vue.mount('#app');
