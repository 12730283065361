import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarDays, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import {
  faX,
  faPencil,
  faAddressBook,
  faArrowLeft,
  faArrowUpRightFromSquare,
  faBell,
  faBuildingColumns,
  faBusinessTime,
  faCalendar,
  faCirclePlay,
  faCircleStop,
  faCity,
  faClipboardCheck,
  faCogs,
  faEnvelope,
  faEuroSign,
  faEye,
  faFile,
  faFolder,
  faGlobe,
  faHandshake,
  faHourglassHalf,
  faMapLocationDot,
  faMapPin,
  faPenToSquare,
  faPercent,
  faPersonChalkboard,
  faPhone,
  faPlus,
  faPowerOff,
  faRightFromBracket,
  faSignal,
  faSpinner,
  faTag,
  faTags,
  faTrash,
  faUmbrella,
  faUnlock,
  faUser,
  faUserGear,
  faUsers,
  faUserTie,
  faVideo,
  faWheelchair,
  faRss,
  faFileImport,
  faFileCircleQuestion,
  faFileCircleCheck,
  faDownload,
  faClockRotateLeft,
  faPlay,
  faTriangleExclamation,
  faMasksTheater,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faTriangleExclamation,
  faPencil,
  faX,
  faCalendarDays,
  faCircleXmark,
  faPersonChalkboard,
  faMapLocationDot,
  faArrowLeft,
  faSpinner,
  faUser,
  faUsers,
  faFile,
  faClipboardCheck,
  faUserGear,
  faRightFromBracket,
  faUnlock,
  faTrash,
  faHandshake,
  faEuroSign,
  faTags,
  faPhone,
  faUmbrella,
  faWheelchair,
  faCalendar,
  faUserTie,
  faFolder,
  faGlobe,
  faAddressBook,
  faEnvelope,
  faCity,
  faTag,
  faBusinessTime,
  faMapPin,
  faPercent,
  faArrowUpRightFromSquare,
  faBuildingColumns,
  faPenToSquare,
  faEye,
  faHourglassHalf,
  faVideo,
  faBell,
  faCirclePlay,
  faCircleStop,
  faCogs,
  faPowerOff,
  faSignal,
  faPlus,
  faRss,
  faFileImport,
  faFileCircleQuestion,
  faFileCircleCheck,
  faDownload,
  faClockRotateLeft,
  faPlay,
  faMasksTheater,
);

export const iconList: string[][] = [
  ['far', 'calendar-days'],
  ['far', 'circle-xmark'],
  ['fas', 'person-chalkboard'],
  ['fas', 'map-location-dot'],
  ['fas', 'spinner'],
  ['fas', 'user'],
  ['fas', 'users'],
  ['fas', 'file'],
  ['fas', 'clipboard-check'],
  ['fas', 'user-gear'],
  ['fas', 'right-from-bracket'],
  ['fas', 'unlock'],
  ['fas', 'trash'],
  ['fas', 'handshake'],
  ['fas', 'euro-sign'],
  ['fas', 'tags'],
  ['fas', 'phone'],
  ['fas', 'umbrella'],
  ['fas', 'wheelchair'],
  ['fas', 'calendar'],
  ['fas', 'user-tie'],
  ['fas', 'folder'],
  ['fas', 'globe'],
  ['fas', 'address-book'],
  ['fas', 'envelope'],
  ['fas', 'city'],
  ['fas', 'tag'],
  ['fas', 'business-time'],
  ['fas', 'map-pin'],
  ['fas', 'percent'],
  ['fas', 'arrow-up-right-from-square'],
  ['fas', 'building-columns'],
  ['fas', 'pen-to-square'],
  ['fas', 'eye'],
  ['fas', 'hourglass-half'],
  ['fas', 'video'],
  ['fas', 'bell'],
  ['fas', 'circle-play'],
  ['fas', 'circle-stop'],
  ['fas', 'cogs'],
  ['fas', 'power-off'],
  ['fas', 'signal'],
  ['fas', 'plus'],
  ['fas', 'rss'],
  ['fas', 'file-import'],
  ['fas', 'file-circle-question'],
  ['fas', 'file-circle-check'],
  ['fas', 'download'],
  ['fas', 'clock-rotate-left'],
  ['fas', 'play'],
];
